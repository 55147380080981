import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'tailwindcss/tailwind.css';
import tokoSyahrul from './../Assets/Images/Toko-Syahrul.jpg';
import portoKu from './../Assets/Images/portotest.jpg';
import projectFilm from './../Assets/Images/project-syahrulfilms.jpg';
import perpus from './../Assets/Images/perpustakaan.jpg';

const Project = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div className="min-h-screen bg-gray-300">
            <section id="project" data-aos="fade-up" className="mt-12 py-12">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl font-bold text-center mb-12 text-black">My Projects</h1>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {[{
                            image: tokoSyahrul,
                            title: "Toko Syahrul Online",
                            subtitle: "Rulztarito",
                            description: "Toko Syahrul merupakan Sebuah aplikasi Toko Online berbasis web, Aplikasi ini dapat dimanfaatkan untuk Pengelolaan Toko Online, pada Web ini dilengkapi dengan fitur CRUD yaitu dapat digunakan untuk Menambah, Melihat, Update, dan Delete dari data barang yang ada pada Toko Online, kemudian Web ini juga dapat digunakan untuk menampilkan Riwayat dari Hasil Penjualan.",
                            additionalInfo: "Username : Syahrul\nPassword : syahrul123",
                            link: "https://toko-syahrul.muhsyahrulr.tech/"
                        }, {
                            image: portoKu,
                            title: "Porto Ku",
                            subtitle: "Dicoding",
                            description: "Website Berikut Merupakan Website Submision pada Saat Belajar pada Platform Dicoding dengan Kelas Belajar Dasar Pemrograman Web.",
                            link: "https://rulz1.netlify.app/"
                        }, {
                            image: projectFilm,
                            title: "Project React JS Rulztarito Films",
                            subtitle: "Rulztarito",
                            description: "Project Website berikut dibuat dengan tutorial pada youtube Dea Afrizal Belajar membuat website React JS.",
                            link: "https://project-syahrulfilms.netlify.app/"
                        }, {
                            image: perpus,
                            title: "Final Project API PERPUSTAKAAN (Library Ku)",
                            subtitle: "Vocasia",
                            description: "Library Ku Merupakan sebuah Sistem API Perpustakaan yang dirancang sebagai solusi komprehensif untuk memenuhi kebutuhan manajemen perpustakaan modern. Dengan menyediakan operasi CRUD API ini memberikan pengguna kemudahan dalam menambahkan, membaca, memperbarui, dan menghapus informasi terkait buku-buku dalam koleksi perpustakaan. Ini memberikan fleksibilitas dalam manajemen inventaris buku, memastikan pengelolaan koleksi yang efisien.",
                            link: "https://github.com/MuhSyahrul6/final-project-api-perpustakaan/"
                        }].map((project, index) => (
                            <div data-aos="fade-up">
                                <div key={index} className="bg-gray-100 shadow-lg rounded-lg overflow-hidden transition-transform duration-300 transform hover:scale-105 aos-init aos-animate">
                                    <img src={project.image} className="w-full h-48 object-cover" alt={project.title} />
                                    <div className="p-6">
                                        <h5 className="text-xl font-semibold text-gray-800 mb-3">{project.title}</h5>
                                        <h6 className="text-gray-600 mb-3">{project.subtitle}</h6>
                                        <p className="text-gray-700 mb-4 hover:line-clamp-none line-clamp-3">{project.description}</p>
                                        {project.additionalInfo && <p className="text-sm text-gray-500 whitespace-pre-line">{project.additionalInfo}</p>}
                                        <a href={project.link} className="relative inline-block text-lg group">
                                            <span className="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-blue-500 transition-colors duration-300 ease-out border-2 border-blue-500 rounded-lg group-hover:text-white">
                                                <span className="absolute inset-0 w-full h-full px-5 py-3 rounded-lg bg-blue-500"></span>
                                                <span className="absolute left-0 w-48 h-48 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-blue-600 group-hover:-rotate-180 ease"></span>
                                                <span className="relative">Cek Website</span>
                                            </span>
                                            <span className="absolute bottom-0 right-0 w-full h-1 transition-all duration-300 origin-top-left -rotate-180 -translate-x-full bg-blue-600 group-hover:translate-x-0 ease"></span>
                                            <span className="absolute top-0 left-0 w-full h-1 transition-all duration-300 origin-bottom-right rotate-180 -translate-x-full bg-blue-600 group-hover:translate-x-0 ease"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Project;
