import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    const [theme, setTheme] = useState(() => {
        const savedTheme = localStorage.getItem("theme");
        return savedTheme ? savedTheme : "light";
    });

    const [menuOpen, setMenuOpen] = useState(false);
    const [themeDropdownOpen, setThemeDropdownOpen] = useState(false);

    const themeDropdownRef = useRef(null);
    const mobileMenuRef = useRef(null);

    const handleThemeChange = (newTheme) => {
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme);
        setThemeDropdownOpen(false);
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const toggleThemeDropdown = () => {
        setThemeDropdownOpen(!themeDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (
            themeDropdownRef.current && !themeDropdownRef.current.contains(event.target)
        ) {
            setThemeDropdownOpen(false);
        }
        if (
            mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)
        ) {
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="bg-gray-100 text-black shadow-md fixed w-full z-10 top-0">
            <div className="container mx-auto p-3">
                <div className="flex justify-between items-center">
                    <div className="logo">
                        <h1 className="font-extrabold text-2xl font-Riot">My Portfolio</h1>
                    </div>
                    <div className="menu md:flex hidden pr-20">
                        <ul className="flex space-x-6">
                            <li className="nav-item">
                                <Link to="/" className="nav-link hover:text-blue-500">Home</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className="nav-link hover:text-blue-500">About</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/skill" className="nav-link hover:text-blue-500">Skills</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/project" className="nav-link hover:text-blue-500">Project</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/contact" className="nav-link hover:text-blue-500">Contact</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex items-center">
                        <div className="relative mx-3" ref={themeDropdownRef}>
                            <button
                                className="btn nav-link dropdown-toggle flex items-center"
                                onClick={toggleThemeDropdown}
                            >
                                {theme === "light" ? "☀️" : "🌙"}
                            </button>
                            {themeDropdownOpen && (
                                <ul className="absolute right-0 mt-2 w-32 bg-white border border-gray-300 shadow-md rounded-md">
                                    <li>
                                        <button
                                            onClick={() => handleThemeChange("light")}
                                            className="block px-4 py-2 text-left hover:bg-gray-100 hover:text-black w-full"
                                        >
                                            ☀️ Light
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={() => handleThemeChange("dark")}
                                            className="block px-4 py-2 text-left hover:bg-gray-100 hover:text-black w-full"
                                        >
                                            🌙 Dark
                                        </button>
                                    </li>
                                    <li>
                                        <button
                                            onClick={() => handleThemeChange("auto")}
                                            className="block px-4 py-2 text-left hover:bg-gray-100 hover:text-black w-full"
                                        >
                                            🌐 Auto
                                        </button>
                                    </li>
                                </ul>
                            )}
                        </div>
                        <div className="relative mx-3 md:hidden" ref={mobileMenuRef}>
                            <button
                                className="btn nav-link dropdown-toggle flex items-center"
                                onClick={toggleMenu}
                            >
                                ☰
                            </button>
                            {menuOpen && (
                                <ul className="absolute right-0 mt-2 w-32 bg-white border border-gray-300 shadow-md rounded-md">
                                    <li>
                                        <Link to="/" className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500" onClick={() => setMenuOpen(false)}>Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about" className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500" onClick={() => setMenuOpen(false)}>About</Link>
                                    </li>
                                    <li>
                                        <Link to="/skill" className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500" onClick={() => setMenuOpen(false)}>Skills</Link>
                                    </li>
                                    <li>
                                        <Link to="/project" className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500" onClick={() => setMenuOpen(false)}>Project</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact" className="block px-4 py-2 hover:bg-gray-100 hover:text-blue-500" onClick={() => setMenuOpen(false)}>Contact</Link>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
