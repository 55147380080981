import React, { useEffect } from 'react';
import Typed from 'typed.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import syahrulImage from './../Assets/Images/syahrul.jpg';
import backgroundImage from './../Assets/Images/fire.gif';
import cvPDF from './../Assets/cv/cv.pdf';

const About = () => {
    useEffect(() => {
        AOS.init();

        const typed = new Typed("#element", {
            strings: ["Muhammad Syahrul Romadhon"],
            typeSpeed: 80,
            loop: true,
            showCursor: true,
        });

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <div className="min-h-screen flex flex-col bg-gray-300">
            <section id="about" data-aos="zoom-in-down" className="container mx-auto px-6 md:px-14 flex-grow mt-20 mb-16">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
                    <div className="flex justify-center xl:hidden mt-4">
                        <div className="relative group">
                            <div className="absolute -inset-11 flex items-center justify-center">
                                <img
                                    src={backgroundImage}
                                    alt="Background"
                                    className=""
                                    style={{ height: "350px", width: "90%", objectFit: 'cover', objectPosition: 'center', filter: 'blur(0px)', zIndex: -1 }}
                                />
                            </div>
                            <img
                                src={syahrulImage}
                                alt="Muhammad Syahrul Romadhon"
                                className="relative rounded-full shadow-lg transform transition-transform duration-300 group-hover:scale-110"
                                style={{ height: "300px", width: "300px" }}
                            />
                            <div className="absolute inset-0 rounded-full border-4 border-transparent animate-fire"></div>
                        </div>

                    </div>
                    <div className="md:col-span-1 bg-gray-100 p-8 rounded-lg shadow-lg">
                        <h1 className="font-bold text-left text-3xl mb-4 font-rubik">About Me</h1>
                        <h4 className="text-2xl mb-4 text-left font-bold text-transparent bg-gradient-to-r from-blue-500 via-pink-500 to-yellow-300 bg-clip-text">
                            <span id="element"></span>
                        </h4>
                        <p className="mb-4 text-left text-gray-700">Hi everyone, my name is Muhammad Syahrul Romadhon. I am a Junior Front-End Developer and Back-End Developer.</p>
                        <p className="mb-4 text-left text-gray-700">I live in Yogyakarta, Indonesia. I was born in Yogyakarta, 06 November 2002. I am a student at the Indonesian Digital Technology University.</p>
                        <p className="mb-4 text-left text-gray-700">I studied various IT fields, such as web programming, networking, and the Internet of Things. I understand the basics of Java, PHP, HTML, and CSS languages.</p>
                        <p className="mb-4 text-left text-gray-700">I've learned about network configuration, hardware maintenance, and software installation. I am a disciplined and hardworking person. I enjoy learning new things and can work independently or as part of a team.</p>
                        <p className="mb-4 text-left text-gray-700">My hobby is Playing Game, Watching Anime & drakor and Riding.</p>
                        <h1 className="font-bold text-2xl mb-4 text-left font-rubik">Resume</h1>
                        <p className="text-left text-gray-700">You can read my resume <a href={cvPDF} className="text-blue-500 hover:underline">here</a>.</p>
                    </div>

                    <div className="hidden xl:flex justify-center">
                        <div className="relative group">
                            <div className="absolute -inset-11 flex items-center justify-center">
                                <img
                                    src={backgroundImage}
                                    alt="Background"
                                    className=""
                                    style={{ height: "350px", width: "90%", objectFit: 'cover', objectPosition: 'center', filter: 'blur(0px)', zIndex: -1 }}
                                />
                            </div>
                            <img
                                src={syahrulImage}
                                alt="Muhammad Syahrul Romadhon"
                                className="relative rounded-full shadow-lg transform transition-transform duration-300 group-hover:scale-110"
                                style={{ height: "300px", width: "300px" }}
                            />
                            <div className="absolute inset-0 rounded-full border-4 border-transparent animate-fire"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default About;
