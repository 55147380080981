import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';
import vocasiaBackend from "./../Assets/certificate/sertif-backend.jpg";
import msibVocasia from "./../Assets/certificate/msib-vocasia.jpg";
import vocaBackend from "./../Assets/certificate/vocasia_Back-End.png";
import suskesKarir from "./../Assets/certificate/vocasia_Sukses-Karir.png";
import dasarPemrogramanWeb from "./../Assets/certificate/dasar-pemrograman-web.jpg";
import dasarGoogleCloud from "./../Assets/certificate/dasar-google-cloud.jpg";
import dasarJavascript from "./../Assets/certificate/dasar-js.jpg";
import dasarManajemenProyek from "./../Assets/certificate/dasar-manajemen-proyek.jpg";
import dasarGithub from "./../Assets/certificate/github.jpg";
import dasarDevOps from "./../Assets/certificate/dasar-devops.jpg";
import pengembangSoftware from "./../Assets/certificate/dasar-pengembang-software.jpg";
import softwareDeveloper from "./../Assets/certificate/software-developer.jpg";
import pemrogramanJava from "./../Assets/certificate/pemrograman-java.jpg";
import dasarSQL from "./../Assets/certificate/sql.jpg";
import pemrogramanPython from "./../Assets/certificate/phyton.jpg";
import visualData from "./../Assets/certificate/dasar-visualisasi-data.jpg";
import jsDasar from "./../Assets/certificate/Skilvul-JS_Dasar.jpg";
import jsIntermediate from "./../Assets/certificate/Skilvul-JS_Intermediate.jpg";
import boostrap5 from "./../Assets/certificate/Skilvul-Bootstrap5.jpg";
import gitDanGithubDasar from "./../Assets/certificate/Skilvul-Git.jpg";


const certifications = [
    {
        title: 'BACKEND DEVELOPER',
        provider: 'Vocasia',
        description: 'Telah berhasil memenuhi persyaratan untuk diakui sebagai Back-End Developer pada Vocasia.',
        image: vocasiaBackend,
        link: '#'
    },
    {
        title: 'Sertifikat Kelulusan MSIB Batch 5',
        provider: 'Vocasia',
        description: 'Telah berhasil menyelesaikan Program Studi Independen pada Program MSIB Vocasia X Kampus Merdeka Angkatan 5.',
        image: msibVocasia,
        link: '#'
    },
    {
        title: 'Intensive Bootcamp Back End Development',
        provider: 'Vocasia',
        description: 'Menyelesaikan Kelas Back End pada Program SIB vocasia X Kampus Merdeka Angkatan 5.',
        image: vocaBackend,
        link: 'https://vocasia.id/user/get_certificate/500/22927'
    },
    {
        title: 'Sukses Karir Profesional Melalui Komunikasi Publik',
        provider: 'Vocasia',
        description: 'Menyelesaikan Kelas Sukses Karir Profesional Melalui Komunikasi Publik.',
        image: suskesKarir,
        link: 'https://vocasia.id/user/get_certificate/22/22927'
    },
    {
        title: 'Belajar Dasar Pemrograman Web',
        provider: 'Dicoding',
        description: 'Telah berhasil Menyelesaikan Kelas Dasar Pemrograman Web dan Menyelesaikan Submision membuat sebuah Portofolio.',
        image: dasarPemrogramanWeb,
        link: 'https://www.dicoding.com/certificates/EYX4YR66OZDL'
    },
    {
        title: 'Belajar Dasar Google Cloud',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi dasar Google Cloud, dari Cloud Computing, Server dan Jaringan, Storage dan Database, Security dan Compliance, Cost dan Billing dan Meningkatkan Nilai Bisnis.',
        image: dasarGoogleCloud,
        link: 'https://www.dicoding.com/certificates/JLX1W164NP72'
    },
    {
        title: 'Belajar Dasar Pemograman Javascript',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi dasar JavaScript, dari fundamental, sintaks dan fitur ES6, hingga konsep automation test.Untuk pengembangan aplikasi web menggunakan Node.Js.',
        image: dasarJavascript,
        link: 'https://www.dicoding.com/certificates/1RXY04KKMZVM'
    },
    {
        title: 'Belajar Dasar Manajemen Proyek',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi dasar Manajemen Proyek, dari Siklus dan Metodologi Manajemen Proyek, Struktur dan Budaya Organisasi dan Mengejar Karier Manajemen Proyek.',
        image: dasarManajemenProyek,
        link: 'https://www.dicoding.com/certificates/07Z68RW5MXQR'
    },
    {
        title: 'Belajar Dasar Git dengan Github',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi cara mengelola data atau kode menggunakan Git dengan GitHub, mulai dari dasar-dasar Git hingga berkolaborasi dengan tim.',
        image: dasarGithub,
        link: 'https://www.dicoding.com/certificates/MRZMLGEYRXYQ'
    },
    {
        title: 'Belajar Dasar-Dasar DevOps',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi DevOps Di akhir kelas, siswa dapat memahami bagaimana kultur dan praktik DevOps mampu meningkatkan nilai teknis serta bisnis perusahaan.',
        image: dasarDevOps,
        link: 'https://www.dicoding.com/certificates/4EXG4YL4EPRL'
    },
    {
        title: 'Memulai Dasar Pemrograman untuk Menjadi Pengembang Software',
        provider: 'Dicoding',
        description: 'Mempelajari materi langkah menjadi pengembang software dari tahapan analisis dan perencanaan sampai modifikasi perangkat lunak beserta dokumentasi.',
        image: pengembangSoftware,
        link: 'https://www.dicoding.com/certificates/JLX1W30WJP72'
    },
    {
        title: 'Meniti Karier sebagai Software Developer',
        provider: 'Dicoding',
        description: 'Mempelajari materi berbagai jalur yang tersedia sebagai Software Developer dari sudut pandang developer ahli yang sudah meniti karier di bidangnya dengan mengacu pada standar kompetensi industri.',
        image: softwareDeveloper,
        link: 'https://www.dicoding.com/certificates/EYX4Y20OWZDL'
    },
    {
        title: 'Memulai Pemrograman Dengan Java',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi Memulai Pemrograman Dengan Java, dengan mempelajari Pengenalan Java, Java Dasar, Control Flow, Collection Type, Object-Oriented Programming (OOP), Common Class, dan yang terakhir mengerjakan Studi Kasus.',
        image: pemrogramanJava,
        link: 'https://www.dicoding.com/certificates/4EXG46E89PRL'
    },
    {
        title: 'Belajar Dasar Structured Query Language (SQL)',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi Belajar Dasar Structured Query Language (SQL), dengan mempelajari bahasa Structured Query Language (SQL).',
        image: dasarSQL,
        link: 'https://www.dicoding.com/certificates/4EXG4YGLGPRL'
    },
    {
        title: 'Memulai Pemrograman dengan Python',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi Memulai Pemrograman dengan Python, dengan mempelajari program Python menggunakan berbagai IDE yang telah dipelajari, seperti Visual Studio Code, Jupyter Notebook, dan Google Colaboratory.',
        image: pemrogramanPython,
        link: 'https://www.dicoding.com/certificates/98XWV6J89PM3'
    },
    {
        title: 'Belajar Dasar Visualisasi Data',
        provider: 'Dicoding',
        description: 'Telah berhasil lulus di ujian akhir dengan materi Belajar Dasar Visualisasi Data, dengan mempelajari membuat sebuah visualisasi data yang efektif menggunakan Google Sheets sesuai teknik praktik terbaik industri, sehingga meningkatkan nilainya dalam berkarier sebagai Software Developer.',
        image: visualData,
        link: 'https://www.dicoding.com/certificates/81P271JJNZOY'
    },
    {
        title: 'JavaScript Dasar',
        provider: 'SkilVul',
        description: 'Telah berhasil Menyelesaikan Kelas JavaScript Dasar, dengan mempelajari cara membuat sebuah website menjadi lebih interaktif dengan menambahkan beberapa program yang ditulis menggunakan JavaScript.',
        image: jsDasar,
        link: 'https://skilvul.com/courses/javascript-dasar/student/cll3p55iv0mma01qkofw91vbg'
    },
    {
        title: 'JavaScript Intermediate',
        provider: 'SkilVul',
        description: 'Telah berhasil menyelesaikan kelas JavaScript Intermediate, dengan mendalami materi lanjutan dari kelas JavaScript Dasar. JavaScript Intermediate banyak membahas topik-topik penting yang pastinya akan bisa langsung diimplementasikan pada proyek.',
        image: jsIntermediate,
        link: 'https://skilvul.com/courses/javascript-intermediate/student/cll3p55iv0mma01qkofw91vbg'
    },
    {
        title: 'Bootstrap 5 - Membuat Website Landing Page',
        provider: 'SkilVul',
        description: 'Telah berhasil menyelesaikan kelas Bootstrap 5 - Membuat Website Landing Page, dengan mempelajari topik-topik penting pada Bootstrap 5 dan menerapkannya dengan membuat sebuah website landing page.',
        image: boostrap5,
        link: 'https://skilvul.com/courses/bootstrap-5-membuat-website-landing-page/student/cll3p55iv0mma01qkofw91vbg'
    },
    {
        title: 'Git & GitHub Dasar',
        provider: 'SkilVul',
        description: 'Telah berhasil menyelesaikan kelas Git & GitHub Dasar, dengan mempelajari basic workflow dan fitur utama dari Git serta mempelajari bagaimana para programmer berkolaborasi menggunakan GitHub.',
        image: gitDanGithubDasar,
        link: 'https://skilvul.com/courses/git-dan-github-dasar/student/cll3p55iv0mma01qkofw91vbg'
    },
    // Add more certifications as necessary
];

const Certification = () => {
    useEffect(() => {
        AOS.init({ duration: 200 });
    }, []);

    return (
        <div className="min-h-screen bg-gray-300">
            <section id="certifications" className="py-10 mt-10" data-aos="fade-up">
                <div className="container mx-auto px-4">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold mb-6">Certifications</h1>
                        <div className="inline-flex space-x-4">
                            <Link to="/skill" className="btn skill text-lg px-4 py-2 border bg-white rounded-lg hover:bg-gray-200 transition duration-300">
                                Language
                            </Link>
                            <Link to="#" className="btn skill text-lg px-4 py-2 border bg-white rounded-lg hover:bg-gray-200 transition duration-300">
                                Certifications
                            </Link>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-4">
                        {certifications.map((cert, index) => (
                            <div key={index} data-aos="zoom-in-up" data-aos-delay={index * 100}>
                                <div className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center transform transition duration-500 hover:scale-110">
                                    <img src={cert.image} className="card-img-top rounded-md mb-4" alt={cert.title} />
                                    <div className="text-center">
                                        <h5 className="text-xl font-semibold mb-2">{cert.title}</h5>
                                        <h6 className="text-sm text-gray-600 mb-3">{cert.provider}</h6>
                                        <p className="text-gray-700 mb-4 line-clamp-3 hover:line-clamp-none">{cert.description}</p>
                                        <a href={cert.link} className="px-4 py-2 bg-blue-600 text-white text-lg font-medium rounded-lg hover:bg-blue-700 transition duration-300">Lihat Detail Sertifikasi</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Certification;
