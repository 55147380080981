import React from "react";

const Footer = () => {
    return (
        <footer className="border-t bg-gray-100 fixed bottom-0 w-full">
            <div className="container mx-auto">
                <div className="flex justify-center">
                    <p className="m-2 text-center text-sm font-medium">
                        CopyRight © {new Date().getFullYear()} - Made with ❤ Rulztarito
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
