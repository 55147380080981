import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import 'tailwindcss/tailwind.css';
import htmlIcon from "./../Assets/tech/html5.svg";
import cssIcon from "./../Assets/tech/css3-svgrepo-com.svg";
import jsIcon from "./../Assets/tech/javascript-svgrepo-com.svg";
import bootstrapIcon from "./../Assets/tech/bootstrap-svgrepo-com.svg";
import nodejsIcon from "./../Assets/tech/node-js-svgrepo-com.svg";
import phpIcon from "./../Assets/tech/php.svg";
import laravelIcon from "./../Assets/tech/laravel.svg";
import reactIcon from "./../Assets/tech/react.svg";
import tailwindIcon from "./../Assets/tech/tailwindcss.svg";
import expressIcon from "./../Assets/tech/exp.svg";
import javaIcon from "./../Assets/tech/java.svg";
import kotlinIcon from "./../Assets/tech/kotlin.svg";
import pythonIcon from "./../Assets/tech/python.svg";

const skillsData = [
    { icon: htmlIcon, alt: "HTML", title: "HTML" },
    { icon: cssIcon, alt: "CSS", title: "CSS" },
    { icon: jsIcon, alt: "JavaScript", title: "JavaScript" },
    { icon: bootstrapIcon, alt: "Bootstrap", title: "Bootstrap" },
    { icon: nodejsIcon, alt: "Node.js", title: "Node.js" },
    { icon: phpIcon, alt: "PHP", title: "PHP" },
    { icon: laravelIcon, alt: "Laravel", title: "Laravel" },
    { icon: reactIcon, alt: "React", title: "React" },
    { icon: tailwindIcon, alt: "Tailwind CSS", title: "Tailwind CSS" },
    { icon: expressIcon, alt: "Express", title: "Express" },
    { icon: javaIcon, alt: "Java", title: "Java" },
    { icon: kotlinIcon, alt: "Kotlin", title: "Kotlin" },
    { icon: pythonIcon, alt: "Python", title: "Python" },
];

const Skills = () => {
    useEffect(() => {
        AOS.init({ duration: 200 });
    }, []);

    return (
        <div className="min-h-screen bg-gray-300 mb-8">
            <section id="skills" className="container mx-auto mt-12 px-6 py-8">
                <div className="text-center mb-12">
                    <h1 className="text-4xl font-bold mb-4">Skills</h1>
                    <div className="inline-flex space-x-4">
                        <Link to="#" className="btn skill text-lg px-4 py-2 border bg-white rounded-lg hover:bg-gray-200 transition duration-300">
                            Language
                        </Link>
                        <Link to="/certification" className="btn skill text-lg px-4 py-2 border bg-white rounded-lg hover:bg-gray-200 transition duration-300">
                            Certifications
                        </Link>
                    </div>
                </div>

                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-8 ">
                    {skillsData.map((skill, index) => (
                        <div data-aos="zoom-in-up"
                            data-aos-delay={index * 100}>
                            <div
                                key={index}
                                className="bg-gray-100 shadow-lg rounded-lg p-6 flex flex-col items-center transform transition duration-500 hover:scale-110">
                                <img src={skill.icon} alt={skill.alt} className="w-16 h-16 mb-4" title={skill.title} />
                                <h2 className="text-lg font-medium">{skill.title}</h2>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default Skills;
