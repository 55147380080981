import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'tailwindcss/tailwind.css';

const Contact = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const scriptURL = 'https://formspree.io/f/mwkgpeeg';
        const form = e.target;
        const btnKirim = document.querySelector('.btn-kirim');
        const btnLoading = document.querySelector('.btn-loading');
        const myAlert = document.querySelector('.alert');

        btnLoading.classList.toggle('hidden');
        btnKirim.classList.toggle('hidden');

        try {
            const response = await fetch(scriptURL, {
                method: 'POST',
                body: new FormData(form),
                mode: 'no-cors',
            });
            btnLoading.classList.toggle('hidden');
            btnKirim.classList.toggle('hidden');
            myAlert.classList.toggle('hidden');
            form.reset();
            console.log('Success!', response);
        } catch (error) {
            console.error('Error!', error.message);
        }
    };

    return (
        <div className="min-h-screen bg-gray-300">
            <section id="contact" data-aos="zoom-in-down" className="container mx-auto my-10 p-8">
                <div className="bg-gray-100 shadow-lg rounded-lg p-4">
                    <h1 className="text-4xl font-extrabold text-gray-800 mb-6 text-center">Contact Me</h1>
                    <p className="text-gray-600 text-center mb-8">
                        Please don’t hesitate to get in touch with me by following my social media below:
                    </p>
                    <ul className="flex justify-center space-x-6 mb-8">
                        <li>
                            <a href="https://github.com/MuhSyahrul6" className="text-gray-700 hover:text-gray-900">
                                <i className="fab fa-github fa-2x"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://instagram.com/syahrulz.r/" className="text-gray-700 hover:text-gray-900">
                                <i className="fab fa-instagram fa-2x"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://web.facebook.com/syahrul.m.cinta.9/" className="text-gray-700 hover:text-gray-900">
                                <i className="fab fa-facebook fa-2x"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/muhammad-syahrul-r/" className="text-gray-700 hover:text-gray-900">
                                <i className="fab fa-linkedin fa-2x"></i>
                            </a>
                        </li>
                    </ul>
                    <div className="alert alert-success hidden mb-5 bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold">Terimakasih!</strong>
                        <span className="block sm:inline"> Pesan anda sudah kami terima.</span>
                        <button type="button" className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            <svg className="fill-current h-6 w-6 text-green-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M14.348 14.849l-4.849-4.849-4.849 4.849-1.414-1.414 4.849-4.849-4.849-4.849 1.414-1.414 4.849 4.849 4.849-4.849 1.414 1.414-4.849 4.849 4.849 4.849z" />
                            </svg>
                        </button>
                    </div>
                    <form name="portofolio-contact-form" className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Full Name *</label>
                            <input type="text" id="name" name="nama" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your name" required />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email *</label>
                            <input type="email" id="email" name="email" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your email" required />
                        </div>
                        <div>
                            <label htmlFor="pesan" className="block text-sm font-medium text-gray-700">Message *</label>
                            <textarea id="pesan" name="pesan" rows="4" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" placeholder="Your message" required></textarea>
                        </div>
                        <div className="flex space-x-4">
                            <button type="submit" className="btn-kirim inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Send
                            </button>
                            <button type="button" className="btn-loading hidden inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                                </svg>
                                Loading...
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default Contact;
