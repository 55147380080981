import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Typed from "typed.js";
import { Link } from "react-router-dom";
import rulztaritoImage from "./../Assets/Images/rulztarito.jpg";
import styles from "./../Styles/Home.modul.css";

const Home = () => {
    useEffect(() => {
        AOS.init();

        const typed = new Typed("#element", {
            strings: [
                "Front-End Developer",
                "Back-End Developer"
            ],
            typeSpeed: 80,
            backSpeed: 80,
            loop: true,
            showCursor: true,
        });

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-gray-300">
            <section id="hero" data-aos="zoom-in-down" className="flex-grow container mx-auto flex justify-center items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
                    <div className="md:hidden">
                        <div className="flex justify-center mt-4">
                            <img src={rulztaritoImage} alt="Muhammad Syahrul Romadhon" className="-translate-x-0 md:-translate-x-24 rounded-full w-64 lg:w-72 h-auto object-cover" />
                        </div>
                    </div>
                    <div className="xl:lg:mr-0 md:mr-32">
                        <div className="content mt-4 md:mt-0 translate-x-0 md:translate-x-24"> {/* Menyesuaikan margin-top */}
                            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-extrabold text-gray-900 mb-4">
                                Muhammad Syahrul Romadhon
                            </h1>
                            <h3><span id="element" className={`text-transparent font-bold text-xl bg-gradient-to-r from-blue-500 via-pink-500 to-yellow-300 bg-clip-text ${styles.wave}`}></span></h3>
                            <p className="mt-3 text-base font-bold lg:text-lg text-gray-600">Hi everyone <span className="wave">👋🏼</span>, welcome to my portfolio website</p>
                            <div className="mt-8">  
                            <Link to="/contact" className="btn px-6 py-3 bg-gradient-to-r from-purple-800 via-pink-500 to-red-500 text-white font-semibold rounded-full shadow-lg transform transition duration-300 hover:scale-105 hover:shadow-xl">
                                More Info
                            </Link>
                            </div>
                        </div>
                    </div>
                    <div className="hidden md:block">
                        <div className="flex justify-center">
                            <img src={rulztaritoImage} alt="Muhammad Syahrul Romadhon" className="-translate-x-0 md:-translate-x-24 rounded-full w-64 lg:w-72 h-auto object-cover" />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
